.scaleImg{
    overflow: hidden;
    img{

        transform: scale(1.2);
        transition: transform 1s ease;
    }
    &.doAnim img{
        transform: scale(1);
    }
}
.slideFromBtm{
    opacity: 0;
    transform: translateY(50px);
    transition: transform 500ms cubic-bezier(0.22, 0.61, 0.36, 1), opacity 300ms cubic-bezier(0.22, 0.61, 0.36, 1);
    &.doAnim{
        opacity: 1;
        transform: translateY(0);
    }
}

.longFade{
    opacity: 0;
    transition: opacity 1s ease;
    &.doAnim{
        opacity: 1;
    }
}

@mixin sequence($items, $count){
    @for $i from 1 to $count{    
          // :nth-child(1-8) 
          //#{nth($items, $i + 1)} 
          #{$items}:nth-child(#{$i}){
            // Delay the animation. Delay increases as items loop.
            transition-delay: $i * .12s;
          }
      }
}

.smartSec{
   
    .item{
        opacity: 0;
        transform: translateY(50px);
        transition: transform 500ms ease, opacity 500ms ease;
    }
    &.doAnim{
        .item{
            opacity: 1;
            transform: translateY(0);
        }
        @include sequence(".item",5);
    }
}