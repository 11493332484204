header{
    background: $brand-light;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    @include media-breakpoint-up(lg){
        background: none;
        overflow: hidden;
        transition: background 250ms ease;

    }
    .ldc-logo{
        padding-top: 10px;
        padding-bottom: 10px;
        img{
            position: relative;
            z-index: 2;
        }
        @include media-breakpoint-up(md){
            padding-top: 15px;
            padding-bottom: 15px;
            &::after,
            &::before{
                background: rgba($brand-light,0.9);
                content: "";
                position: absolute;
                top: 0;
                height: 100%;
            }
            &::before{
                width: 200%;
                right: 100%;
            }
            &::after{
                left: 0;
                width: 100%;
                clip-path: polygon(0 0, 85% 0%,100% 101%, 0% 101%);
            }
        }
    }
    .ldc-mb{
        width: 40px;
        margin-right: 8.6666667%;
        span{
            background: #b9ac89;
            display: block;
            height: 4px;
            margin-bottom: 6px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .ldc-nav{
        @include media-breakpoint-down(sm){
            background: $brand-light;
            padding: 8.6666667%;
            position: fixed;
            top: 67px;
            right: 0;
            width: 70%;
            display: none;
            flex-direction: column;
        }
            flex-wrap: wrap;
            align-items: flex-end;
            
            @include media-breakpoint-up(md){
                background: none;
                display: flex;
                flex-direction: row;
                padding: 0;
                position: initial;
                width: inherit;
                transform: none;
            }
            a{
                color:$brand-secondary;
                font-size: 22px;
                text-transform: uppercase;
                position: relative;
                margin-bottom: 20px;
                text-align: right;
                overflow: hidden;
                @include media-breakpoint-up(md){
                    font-size: 16px;
                    text-align: initial;
                }
                &::after{
                    background: white;
                    content: "";
                    height: 2px;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transform: translateX(-100%);
                    transition: transform 250ms ease;
                }
                &.current,
                &:hover{
                    &::after{
                        transform: translateX(0);
                    }
                }
            }

        
        @include media-breakpoint-up(lg){
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            a{
                color:$brand-light;
                margin-bottom: 0;
            }
        }
    }
    &.sticky{
        background: rgba($brand-secondary, 0.9);
    }
}