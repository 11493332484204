// Font
$fontBase: 'Poppins', sans-serif;
$fontTitles: 'Oswald', sans-serif;
$line-height-base: 1.6;

//color

$brand-primary:#9d9770;
$brand-secondary:#404040;
$brand-light: white;
