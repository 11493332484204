.ldc-banner{
    position: relative;
    &_data{
        @include media-breakpoint-down(sm){
            margin: 0 auto;
        }
    }
    .container{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
        p{
            color: $brand-light;
            font-family: $fontTitles;
            font-size: 28px;
            font-weight: 300;
            line-height: 1.16;
            padding: 0;
            margin-bottom: 50px;
            @include media-breakpoint-up(md){
                font-size: 38px;
            }
        }
    }
}

.ldc-somos{
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-breakpoint-up(md){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    h1{
        margin-bottom: 50px;
        strong{
            font-weight: 600;
        }
    }
}

.ldc-generamos{
    position: relative;
    margin-bottom: 120px;
    &::after{
        background: #4c5260;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        @include media-breakpoint-up(md){
            width: 85%;
            clip-path: polygon(0 0, 100% 0, 100% 101%, 38% 101%);
        }
        @include media-breakpoint-up(xxl){
            width: 78%;
        }
    }
    h2{
        color:$brand-light;
        font-family: $fontTitles;
        font-weight: 200;
        font-size: 32px;
        padding-bottom: 50px;
        @include media-breakpoint-up(md){
            font-size: 50px;
            line-height: 1.04;
            padding-top: 50px;
            padding-bottom: 0;
        }
        strong{
            font-weight: 400;
        }
    }
    &_img{
        margin-top: -20px;
        padding-bottom: 30px;
    }
}

.ldc-generamos_proyectos{
    position: relative;
    padding-bottom: 50px;
    @include media-breakpoint-up(lg){
        padding-bottom: 120px;
    }
    
    &::before{
        content: "";
        width: 0;
        height: 0;
        border-style: inset;
        border-style: solid;
        border-width: 188px 0 0 232px;
        border-color: transparent transparent transparent $brand-primary;
        transform:rotate(360deg);
        position: absolute;
        bottom: 99.7%;
        left: 0;
    }
    
    h2{
        margin-bottom: 50px;
    }
    ul{
        padding-top: 40px;
    }
    ::marker{
        color:#a6bcc6;
    }
    &Txt{
        padding-top: 50px;
        @include media-breakpoint-down(sm){
            padding-left: calc(8.6666667% + 15px) !important;
            padding-right: calc(8.6666667% + 15px) !important;
        }
        @include media-breakpoint-up(lg){
            padding-top: 0;
        }
    }
}

.ldc-construimos{
    overflow: hidden;
    margin-bottom: 50px;
    @include media-breakpoint-up(md){
        margin-bottom: 100px;
    }
    &_txt{
        .subTitle{
            font-size: 20px;
            font-weight: 500;
            display: block;
            @include media-breakpoint-up(md){
                font-size: 20px;
            }
        }
        @include media-breakpoint-down(sm){
            padding-left: 0;
            padding-right: 0;
            h2,p{
                padding-left: calc(8.6666667% + 15px);
                padding-right: calc(8.6666667% + 15px);
            }
            img{
                margin-bottom: 50px;
            }
        }
        &:first-child{
            padding-right: 0;
            p{
                @include media-breakpoint-up(md){
                    padding-right: 50px;
                }
                &:nth-of-type(2){
                    margin-bottom: 80px;
                }
            }
        }
        &:nth-child(2){
            p{
                @include media-breakpoint-up(lg){
                    padding-right: 80px;
                }
                &:nth-of-type(2){
                    margin-bottom: 80px;
                    @include media-breakpoint-up(lg){
                        margin-bottom: 175px;
                    }
                    @include media-breakpoint-up(xxl){
                        margin-bottom: 230px;
                    }
                }
            }
        }
        &:last-child{
            p:nth-of-type(2){
                margin-bottom: 20px;
            }
            ul{
                margin-bottom: 50px;
                padding-left: 55px;
            }
        }
    }
    .img-full{
        @include media-breakpoint-down(sm){
            max-width: 100%;
            height: auto;
        }
    }
    .img-float{
        @include media-breakpoint-up(md){
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
}

.ldc-servicios{
    background: #f2fbff;
    position: relative;
    padding-bottom: 100px;
    &::before{
        background: #f2fbff;
        content: "";
        height: 20px;
        width: 100%;
        position: absolute;
        bottom: 100%;
        left: 0;
        z-index: -1;
        @include media-breakpoint-up(md){
            height: 300px;
        }
    }
    h3{
        margin-bottom: 30px;
    }
    p + img{
        padding-top: 65px;
    }
    &_title{
        position: relative;
        margin-bottom: 50px;
        &::before{
            background: $brand-primary;
            content: "";
            height: 2px;
            width: 100%;
            position: absolute;
            right: 100%;
            top: 50%;
        }
    }
    &_txt{
        margin-bottom: 56px;
        @include media-breakpoint-up(lg){
            margin-bottom: 42px;
        }
        @include media-breakpoint-up(xxl){
            margin-bottom: 56px;
        }
        @include media-breakpoint-up(lg){
            p,h3{
                padding-right: 50px;
            }
        }
    }
}
.ldc-compromiso{
    @include media-breakpoint-down(sm){
        background: url(/images/obras-desarrollos-estrategicos-construccion-xs.webp) no-repeat center center /cover;
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }
    @include media-breakpoint-up(lg){
        background: url(/images/obras-desarrollos-estrategicos-construccion.webp) no-repeat center center /cover;
        padding-top: 20vh;
        padding-bottom: 20vh;
    }
    &_txt{ 
        @include media-breakpoint-up(lg){
            padding-left: 0;
            padding-right: 0;
        }
        h2{
            margin-bottom: 40px;
        }
    }
    &_bullets{
        span{
            color: $brand-light;
            font-size: 15px;
            display: block;
            position: relative;
            &::before{
                content: "•";
                color:#b9ac89;
                font-size: 16px;
                margin-right: 5px;
            }
        }
    }
}
.ldc-desarrollos{
    @include media-breakpoint-up(md){
        background: url('/images/ldc-nuestros-desarrollos.webp') no-repeat center center /cover;
        padding-top: 100px;
        padding-bottom: 100px;
        margin-bottom: 100px;
    }
    h2{
        margin-bottom: 50px;
    }
    &_logos{
        justify-content: center;
        figure{
            width: 50%;
            margin-bottom: 40px;
            @include media-breakpoint-up(md){
                width: auto;
                margin-bottom: 0;
            }
        }
        @include media-breakpoint-up(md){
            align-items: center;
           
        }
    }
}
.ldc-contact{
    margin-bottom: 100px;
    
    &_img{
        margin-bottom: 50px;
        @include media-breakpoint-up(md){
            margin-bottom: 0;
        }
    }
    &_form{
        h2{
            margin-bottom: 40px;
        }
        h3{
            margin-bottom: 20px;
        }
        p{
            margin-bottom: 70px;
        }
    }
    form{
        .input-g{
            margin-bottom: 45px;
        }
        label{
            color:#4c5260;
            font-family: $fontTitles;
            font-size: 20px;
            font-weight: 300;
            display: block;
            margin-bottom: 5px;
        }
        input,textarea{
            background: none;
            border:none;
            border-bottom: 1px solid $brand-secondary;
            color:$brand-secondary;
            font-size: 14px;
            font-weight: 300;
            height: 40px;
            width: 100%;
            padding: 0 10px;
            &:focus,
            &.danger{
                outline: none;
                border-bottom-color: $brand-primary;
            }
        }
        button{
            border: none;
            &:focus{
                outline: none;
            }
        }
        .danger,
        .errorCaptcha{
            color:red
        }
    }
    .ldc-secury{
        &_catpcha{
            button{
                background: $brand-primary;
                border: none;
                cursor: pointer;
                font-size: 22px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}

.ldc-xtra{
    height: 100dvh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include media-breakpoint-up(xl){
        height: 100vh;
    }
}

.ldc-contact_tks{
    background: url('/images/ldc-agradecimiento.webp') no-repeat center center /cover;
    @extend .ldc-xtra;
    &Box{
        >span{
            display: block;
            font-family: $fontTitles;
            font-size: 50px;
            font-weight: 700;
            line-height: 1.4;
            margin-bottom: 30px;
            @include media-breakpoint-up(lg){
                font-size: 90px;
            }
        }
    }    
    p{
        color: $brand-primary;
        font-size: 27px;
        line-height: 1.04;
        margin-bottom: 50px;
        @include media-breakpoint-up(lg){
            font-size: 50px;
        }
    }
}

.is_404,
.is_contact{
    header .ldc-nav{
        display: none;
    }
}

.ldc-notFound{
    background: url('/images/ldc-error-404.webp') no-repeat center center /cover;
    font-family: $fontTitles;
    @extend .ldc-xtra;

    h1{
        color:$brand-primary;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 0;
        @include media-breakpoint-up(lg){
            font-size: 90px;
        }
        + span{
            color: #a6bcc6;
            font-size: 30px;
            display: block;
            @include media-breakpoint-up(lg){
                font-size: 50px;
            }
        }
    }
    &_title{
        color: #a6bcc6;
        font-size: 30px;
        line-height: 1.73;
        margin-bottom: 50px;
    }
    ul{
        color:white;
        font-size: 20px;
        font-weight: 300;
        line-height: 2.6;
        text-align: left;
        display: inline-block;
        ::marker{
            color:#b9ac89;
        }
        margin-bottom: 50px;
    }
    .smalltext{
        color: white;
        font-size: 20px;
        font-weight: 300;
    }
}

.alert-danger{
    background: rgb(215, 83, 83);
    color:white;
    padding: 15px;
    margin-bottom: 40px;
    text-align: left;
}