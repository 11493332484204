body{
    color:$brand-secondary;
    font-family: $fontBase;
    font-weight: 300;
    font-size: 15px;

    strong{
        font-weight: 600;
    }

    &.noScroll{
        main{
            height: 100vh;
            overflow: hidden;
        }
        .ldc-mb{
            span{
                &:first-child{
                    transform: rotate(45deg) translate(7px, 7px);
                }
                &:nth-child(2){
                    opacity: 0;
                    transform: translateY(-100%);
                }
                &:nth-child(3){
                    transform: rotate(-45deg) translate(6px, -7px);
                }
            }
        }
        header nav{
           display: flex;
        }
        .overload{
            background: rgba($brand-secondary,0.7);
            position: fixed;
            top: 0;
            left: 0;
            height: 100dvh;
            width: 100vw;
            z-index: 3;
            @include media-breakpoint-up(xl){
                height: 100vh;
            }
        }
    }
}
a,a:link,a:active{
    text-decoration: none;
}
h1,h1,h3{
    margin-bottom: 0;
}
.img-fluid{
    max-width: 100%;
    height: auto;
}

.img-strech,
.img-strech img{
    width: 100%;
}

.text-center{
    text-align: center;
}

.dFlex{
    display: flex;
    flex-wrap: wrap;
}

.noPad{
    padding-left: 0;
    padding-right: 0;
}

.color-primary{
    color:$brand-primary;
}

.heading{
    font-family: $fontTitles;
    font-size: 48px;
    font-weight: 300;
    line-height: 1.17;
    @include media-breakpoint-up(lg){
        font-size: 52px;
        line-height: 1;
    }
}

.secTitle{
    font-family: $fontTitles;
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 50px;
    line-height: 1.33;
    @include media-breakpoint-up(md){
        font-size: 36px;
        font-weight: 300;
    }
}

.ldcBtn{
    font-family: $fontTitles;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    min-width: 205px;
    height: 53px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    transition: background 250ms ease;
    position: relative;
    overflow: hidden;
    &::before{
        background: $brand-secondary;
        content: "";
        height: 100%;
        width: 15%;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 250ms ease;
        transform: translateX(-100%);
    }
    span{
        display: block;
        position: relative;
        z-index: 1;
    }
    &-primary{
        background: $brand-primary;
        color:$brand-light;
        &:hover{
            color:$brand-light;
        }
    }
    &:hover{
        &::before{
            transform: translateX(0);
        }
    }
}