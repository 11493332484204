// Fonts
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;600;700&family=Poppins:wght@300;500;600&display=swap');

// Variables
@import 'settings';

// Bootstrap
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/bootstrap-reboot';

//theme

@import 'header';
@import 'footer';
@import 'theme';
@import 'body';
@import 'animations';
