footer{
    background: url('/images/ldc-desarrollos.svg') no-repeat left bottom, #4c5260;
    color:$brand-light;
    padding-top: 80px;
    padding-bottom: 100px;
    text-align: center;
    @include media-breakpoint-up(lg){
        text-align: initial;
        padding-bottom: 50px;
    }
    .ldc-ft_logo{
        margin-bottom: 70px;
        @include media-breakpoint-up(lg){
            margin-bottom: 0;
        }
    }
    .ldc-tel{
        margin-bottom: 50px;
        a{
            color:$brand-primary;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 40px;
        }
    }
    .ldc-copy{
        font-size: 14px;
        a{
            color:$brand-light;
        }
    }
}